/*
* Author: LJH
* Date: 2022/1/7
* Description:
*/
import ReactDOM from 'react-dom';
import Toast from './Toast';
function InitToast(dom, { delay=3000, appendTo='body', className }={}) {
  console.log('Toast:', dom)
  if(!InitToast.document) {
    InitToast.document = document.createElement('div');
    document.querySelector(appendTo).appendChild(InitToast.document);
  }
  const toast = <Toast className={className}>{dom}</Toast>;
  ReactDOM.render(toast, InitToast.document);
  if(InitToast.timer) clearTimeout(InitToast.timer);
  InitToast.timer = setTimeout(() => {
    ReactDOM.unmountComponentAtNode(InitToast.document);
  }, delay);
  return toast;
}
export default InitToast;
