import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import { useAppContext } from "../../context/AppContext";
import Button from "../Button";

const codeMessage = {
  en: {
    10009: "Account does not exist, please re-enter",
    11001: "This email address registered before, please change or login",
    11002: "Password must include 8-16 characters with a mix of upper, lower case letters and numbers.",
    11003: "Phone number registered before, please change or login",
    11004: "Invalid verification code",
    11005: "Invalid verification code",
    11006: "This email address is invalid, please re-enter",
    11012: "Email Address is not registered, please re-enter",
    11013: "Incorrect Graphic verification code",
    11014: "Phone number is not registered, please re-enter",
    11015: "Password and Re-confirmed Password do not match",
    11017: "The new trading password is the same as the old password. Please enter another password.",
    11018: "Incorrect ID number",
    11029: "Too frequent operation, please try again 1 hour later",
    11030: "E-mail has been registered in other products, please re-enter",
    11031: "The Phone number has been registered in other products, please re-enter",
    11032: "Email is used in other products",
    11033: "Phone number is used in other products",
    11035: "The email address you entered is already in use. If you want to unlink that AQUMON account, please contact our customer service team at +852 3590 9951.",
    11036: "The phone number you entered is already in use. If you want to unlink that AQUMON account, please contact our customer service team at +852 3590 9951.",
    11037: "The phone number you entered is already linked with an email address. If you want to unlink that AQUMON account, please contact our customer service team at +852 3590 9951.",
    11038: "The email address you entered is already linked with a phone number. If you want to unlink that AQUMON account, please contact our customer service team at +852 3590 9951.",
    11039: "The email address you entered is already linked with a phone number in our system",
    11040: "The phone number you entered is already linked with an email address in our system",
    11041: "This time slot is booked, please choose another one. ",
    11042: "Expired verification code, please re-acquire",
    14006: "Phone number error",
    20002: "Your login account has expired, please login again",
    21002: "Account or Password error",
    21003: "Your account has been locked，please call customer service to unlock: +852 3590 9951",
    21004: "Account is logged in on other device, please re-login",
    21009: "You have input the incorrect trading password 6 times continually. Please reset it.",
    21010: "You have set a transaction password and cannot create it repeatedly.",
    21011: "Incorrect account opening license.",
    21012: "The trading password should contain 6 different non-sequential numbers.",
    21013: "The new password you have input is repeated with the three most recently used passwords. Please input it again.",
    40001: "The service failed. Please try again later",
    40005: "Backend server disconnected, please try again later",
    90000: "Dear customer, our system is under maintanance, please try again later after 30 mins. If you have any questions, please contact us at +852 3590 9951.",
    90001: "Request timed out! There may be a network anomaly or system error. \nPlease try again, if you have any questions, please contact us: +852 3590 9951",
    99999: "Error!\nPlease try again or contact us: +852 3590 9951",
    100000: "Error!\nPlease try again or contact us: +852 3590 9951",
    100001: "Request timed out",
    100002: "Network anomaly",
    50020416: "Available assets is not enough!",
    600204403: "Direct Debits Instructions failed.",
    600204404: "ProcessResult Failed.",
    600204405: "ProcessResult Unknown ResponseCode.",
    600205001: "edda record repeated.",
    600205002: "edda record not exists.",
    600205003: "current edda otp has already regenerated!",
    600205004: "The bank account has successfully passed the eDDA verification. You may now use this bank account to deposit funds.",
    600205005: "The authorization confirmation password has expired, please try to resentd again.",
    600205006: "Edda send otp_regeneration password exceed limit.",
    600205007: "Edda status is illegal.",
    600205008: "Please enter an amount not less than HKD 100.",
    600205009: "You have failed 5 deductions consecutively. If the deduction fails due to insufficient funds in your bank account, the bank may charge a handling fee. Please contact us (+852 3590 9951) or your bank for more information.",
    "-1": "The service failed. Please try again later",
    "11035_qzt":
      "The email address you entered is already in use. If you want to unlink that Qzhitou account, please contact our customer service team at 4008-956-986.",
    "11036_qzt":
      "The phone number you entered is already in use. If you want to unlink that Qzhitou account, please contact our customer service team at 4008-956-986.",
    "11037_qzt":
      "The phone number you entered is already linked with an email address. If you want to unlink that Qzhitou account, please contact our customer service team at 4008-956-986.",
    "11038_qzt":
      "The email address you entered is already linked with a phone number. If you want to unlink that Qzhitou account, please contact our customer service team at 4008-956-986.",
    "21002_1": "Incorrect original password",
    "21003_qzt":
      "Your account has been locked，please call customer service to unlock: 4008-956-986",
    "21003_soft":
      "Your account has been locked，please call customer service to unlock: +852 3590 9951/+86 21 20520567(Shanghai, China)",
    "21003_soft_qzt":
      "Your account has been locked，please call customer service to unlock: 4008-956-986/+86 21 20520567(Shanghai, China)",
    "90001_qzt":
      "Request timed out! There may be a network anomaly or system error. \nPlease try again, if you have any questions, please contact us: 4008-956-986",
    "90001_soft":
      "Request timed out! There may be a network anomaly or system error. \nPlease try again, if you have any questions, please contact us: +852 3590 9951/+86 21 20520567(Shanghai, China)",
    "90001_soft_qzt":
      "Request timed out! There may be a network anomaly or system error. \nPlease try again, if you have any questions, please contact us: 4008-956-986/+86 21 20520567(Shanghai, China)",
    "99999_qzt": "Error!\nPlease try again or contact us: 4008-956-986",
    "99999_soft":
      "Error!\nPlease try again or contact us: +852 3590 9951/+86 21 20520567(Shanghai, China)",
    "99999_soft_qzt":
      "Error!\nPlease try again or contact us: 4008-956-986/+86 21 20520567(Shanghai, China)",
  },
  "zh-cn": {
    10009: "该账号不存在，请重新输入",
    11001: "该邮箱已被注册，请重新输入或登录",
    11002: "密码为8-16位，需包含大写字母、小写字母、数字。",
    11003: "该手机号码已被注册，请重新输入或登录",
    11004: "验证码错误",
    11005: "验证码错误",
    11006: "邮箱格式错误，请重新输入",
    11012: "邮箱未注册，请重新输入",
    11013: "图形验证码错误",
    11014: "手机号码未注册，请重新输入",
    11015: "两次密码输入不一致",
    11017: "新旧交易密码相同，请重新输入",
    11018: "证件号错误",
    11029: "操作过频繁，请1小时后再试",
    11030: "邮箱已在其他产品注册，请重新输入",
    11031: "手机号码已在其他产品注册，请重新输入",
    11032: "邮箱已在其他产品使用",
    11033: "手机号码已在其他产品使用",
    11035: "您输入的邮箱已与其他账户绑定，如有疑问，请致电+852 21552816人工服务。",
    11036: "您输入的手机号码已与其他账户绑定，如有疑问，请致电+852 21552816人工服务。",
    11037: "您输入的手机号码已绑定其他邮箱，\n需要解绑？请致电+852 21552816人工服务。",
    11038: "您输入的邮箱已绑定其他手机号码，\n需要解绑？请致电+852 21552816人工服务。",
    11039: "您输入的邮箱已与其他手机号码绑定",
    11040: "您输入的手机号码已与其他邮箱绑定",
    11041: "该时间段已被预约，请选择其他时间",
    11042: "验证码已失效，请重新获取",
    14006: "手机号格式错误",
    20002: "登录过期，请重新登录",
    21002: "用户名或密码错误",
    21003: "您的账户已被锁定，请致电客服解锁：+852 3590 9951",
    21004: "账号在其他设备登录，请重新登录",
    21009: "您已连续6次输错交易密码，请前往重设",
    21010: "您已设置交易密码，不可重复创建",
    21011: "证件号错误",
    21012: "交易密码为6位不相同不连续的数字",
    21013: "拟用的新密码与最近使用的三个密码重复，请重新输入",
    21015: "银行卡号验证失败",
    40001: "服务出错了，请稍后再试",
    40005: "服务器异常，请稍后再试",
    90000: "尊敬的客户您好，系统正在维护，请您约30分钟后登录重试。如您遇到任何问题，请随时与我们的客户服务团队联络。 ",
    90001: "请求超时！可能存在网络异常或系统错误。\n请尝试重新操作，如有疑问请联系我们：+852 3590 9951",
    99999: "出错了！\n请尝试重新操作，或联系我们：+852 3590 9951",
    100000: "出错了！\n请尝试重新操作，或联系我们：+852 3590 9951",
    100001: "请求超时",
    100002: "网络异常",
    50020416: "可用余额不足！",
    600204401: "此卡无法授权",
    600204402: "此卡多次提交",
    600204403: "开发说用不到",
    600204404: "ProcessResult Failed.",
    600204405: "开发说用不到",
    600205001: "开发说用不到",
    600205002: "开发说用不到",
    600205003: "开发说用不到",
    600205004: "该银行账户已通过电子直接付款授权(eDDA)审核，您可以使用该账户去存款。",
    600205005: "授权确认密码已过期，请重新获取",
    600205006: "该银行卡的授权确认密码今日重发次数已达上限，请于明日再试或使用其他银行卡提交授权申请。",
    600205007: "Edda status is illegal.",
    600205008: "请输入不少于港币100的金额",
    600205009: "您已连续5次扣款失败，若因银行账户资金不足导致扣款失败，银行将收取手续费，建议您联系银行或联系我们（+852 3590 9951）。",
    "-1": "服务出错了，请稍后再试",
    "11035_qzt":
      "您输入的邮箱已与其他账户绑定，如有疑问，请致电4008-956-986人工服务。",
    "11036_qzt":
      "您输入的手机号码已与其他账户绑定，如有疑问，请致电4008-956-986人工服务。",
    "11037_qzt":
      "您输入的手机号码已绑定其他邮箱，\n需要解绑？请致电4008-956-986人工服务。",
    "11038_qzt":
      "您输入的邮箱已绑定其他手机号码，\n需要解绑？请致电4008-956-986人工服务。",
    "21002_1": "旧密码错误",
    "21003_qzt": "您的账户已被锁定，请致电客服解锁：4008-956-986",
    "21003_soft":
      "您的账户已被锁定，请致电客服解锁：+852 3590 9951/+86 21 20520567（中国上海）",
    "21003_soft_qzt":
      "您的账户已被锁定，请致电客服解锁：4008-956-986/+86 21 20520567（中国上海）",
    "90001_qzt":
      "请求超时！可能存在网络异常或系统错误。\n请尝试重新操作，如有疑问请联系我们：4008-956-986",
    "90001_soft":
      "请求超时！可能存在网络异常或系统错误。\n请尝试重新操作，如有疑问请联系我们：+852 3590 9951/+86 21 20520567（中国上海）",
    "90001_soft_qzt":
      "请求超时！可能存在网络异常或系统错误。\n请尝试重新操作，如有疑问请联系我们：4008-956-986/+86 21 20520567（中国上海）",
    "99999_qzt": "出错了！\n请尝试重新操作，或联系我们：4008-956-986",
    "99999_soft":
      "出错了！\n请尝试重新操作，或联系我们：+852 3590 9951/+86 21 20520567（中国上海）",
    "99999_soft_qzt":
      "出错了！\n请尝试重新操作，或联系我们：4008-956-986/+86 21 20520567（中国上海）",
  },
  "zh-hk": {
    10009: "該賬號不存在，請重新輸入",
    11001: "該郵箱已被註冊，請重新輸入或登錄",
    11002: "密碼為8-16位，需包含大寫字母、小寫字母、數字。",
    11003: "該手機號碼已被註冊，請重新輸入或登錄",
    11004: "驗證碼錯誤",
    11005: "驗證碼錯誤",
    11006: "郵箱格式錯誤，請重新輸入",
    11012: "郵箱未註冊，請重新輸入",
    11013: "圖形驗證碼錯誤",
    11014: "手機號未註冊，請重新輸入",
    11015: "兩次密碼輸入不一致",
    11017: "新舊交易密碼相同，請重新輸入",
    11018: "證件號錯誤",
    11029: "操作過頻繁，請1小時後再試",
    11030: "郵箱已在其他產品註冊，請重新輸入",
    11031: "手機號碼已在其他產品註冊，請重新輸入",
    11032: "郵箱已在其他產品使用",
    11033: "手機已在其他產品使用",
    11035: "您輸入的郵箱已與其他賬戶綁定，如有疑問，請致電+852 21552816人工服務。",
    11036: "您輸入的手機號碼已與其他賬戶綁定，如有疑問，請致電+852 21552816人工服務。",
    11037: "您輸入的手機號碼已綁定其他郵箱，\n需要解綁？請致電+852 21552816人工服務。",
    11038: "您輸入的郵箱已綁定其他手機號碼，\n需要解綁？請致電+852 21552816人工服務。",
    11039: "您輸入的郵箱已與其他手機號碼綁定",
    11040: "您輸入的手機號碼已與其他郵箱綁定",
    11041: "該時間段已被預約，請選擇其他時間",
    11042: "驗證碼已失效，請重新獲取",
    14006: "手機號格式錯誤",
    20002: "登錄過期，請重新登錄",
    21002: "用戶名或密碼錯誤",
    21003: "您的賬戶已被鎖定，請致電客服解鎖：+852 3590 9951",
    21004: "賬號在其他設備登錄，請重新登錄",
    21009: "您已連續6次輸錯交易密碼，請前往重設",
    21010: "您已設置交易密碼，不可重複創建",
    21011: "證件號錯誤",
    21012: "交易密碼為6位不相同不連續的數字",
    21013: "擬用的新密碼與最近使用的三個密碼重複，請重新輸入",
    21015: "銀行卡號驗證失敗",
    40001: "服務出錯了，請稍後再試",
    40005: "服務器異常，請稍後再試",
    90000: "尊敬的客戶您好，系統正在維護，請您約30分鐘後登錄重試。如您有任何疑問，歡迎您撥打+852 3590 9951聯絡我們。",
    90001: "請求超時！可能存在網絡異常或系統錯誤。 \n請嘗試重新操作，如有疑問請聯繫我們：+852 3590 9951",
    99999: "出錯了！\n請嘗試重新操作，或聯繫我們：+852 3590 9951",
    100000: "出錯了！\n請嘗試重新操作，或聯繫我們：+852 3590 9951",
    100001: "請求超時",
    100002: "網絡異常",
    50020416: "可用餘額不足！",
    600204404: "ProcessResult Failed.",
    600205004: "該銀行賬戶已通過電子直接付款授權(eDDA)審核，您可以使用該賬戶去存款。",
    600205005: "授权确认密码已过期，请重新获取",
    600205006: "該銀行卡的授權確認密碼今日重發次數已達上限，請於明日再試或使用其他銀行卡提交授權申請。",
    600205008: "請輸入不少於港幣100的金額",
    600205009: "您已連續5次扣款失敗，若因銀行賬戶資金不足導致扣款失敗，銀行將收取手續費，建議您聯繫銀行或聯繫我們（+852 3590 9951）。",
    "-1": "服務出錯了，請稍後再試",
    "11035_qzt":
      "您輸入的郵箱已與其他賬戶綁定，如有疑問，請致電4008-956-986人工服務。",
    "11036_qzt":
      "您輸入的手機號碼已與其他賬戶綁定，如有疑問，請致電4008-956-986人工服務。",
    "11037_qzt":
      "您輸入的手機號碼已綁定其他郵箱，\n需要解綁？請致電4008-956-986人工服務。",
    "11038_qzt":
      "您輸入的郵箱已綁定其他手機號碼，\n需要解綁？請致電4008-956-986人工服務。",
    "21002_1": "舊密碼錯誤",
    "21003_qzt": "您的賬戶已被鎖定，請致電客服解鎖：4008-956-986",
    "21003_soft":
      "您的賬戶已被鎖定，請致電客服解鎖：+852 3590 9951/+86 21 20520567（中國上海）",
    "21003_soft_qzt":
      "您的賬戶已被鎖定，請致電客服解鎖：4008-956-986/+86 21 20520567（中國上海）",
    "90001_qzt":
      "請求超時！可能存在網絡異常或系統錯誤。\n請嘗試重新操作，如有疑問請聯繫我們：4008-956-986",
    "90001_soft":
      "請求超時！可能存在網絡異常或系統錯誤。 \n請嘗試重新操作，如有疑問請聯繫我們：+852 3590 9951/+86 21 20520567（中國上海）",
    "90001_soft_qzt":
      "請求超時！可能存在網絡異常或系統錯誤。 \n請嘗試重新操作，如有疑問請聯繫我們：4008-956-986/+86 21 20520567（中國上海）",
    "99999_qzt": "出錯了！\n請嘗試重新操作，或聯繫我們：4008-956-986",
    "99999_soft":
      "出錯了！\n請嘗試重新操作，或聯繫我們：+852 3590 9951/+86 21 20520567（中國上海）",
    "99999_soft_qzt":
      "出錯了！\n請嘗試重新操作，或聯繫我們：4008-956-986/+86 21 20520567（中國上海）",
  },
};

const ErrorPopup = () => {
  const intl = useIntl();
  const { errorCode, setErrorCode } = useAppContext();
  return (
    errorCode && (
      <div
        css={css`
          position: fixed;
          z-index: 9999;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            border-radius: 20px;
            background: white;
            padding: 30px;
            display: flex;
            flex-direction: column;
          `}
        >
          <h2>
            <strong>
              {
                { en: "Oops!", "zh-cn": "哎呀！", "zh-hk": "哎呀！" }[
                  intl.locale
                ]
              }
            </strong>
          </h2>
          <h4>
            {codeMessage[intl.locale][errorCode]} ({errorCode})
          </h4>
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              onClick={() => {
                setErrorCode(null);
              }}
            >
              {{ en: "OK", "zh-cn": "关闭", "zh-hk": "關閉" }[intl.locale]}
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default ErrorPopup;
