import '../styles/common.scss';
import '../styles/loading.scss';

import { useIntl } from 'gatsby-plugin-intl';
import React, { useContext, useRef, useState } from 'react';
import queryString from 'query-string';
const apiUrl = {
  live: 'https://smarthk.aqumon.com',
  test: 'https://test-smarthk.aqumon.com',
}[process.env.GATSBY_ENV];
const mainLandUrl = {
  live: 'https://www.aqumon.com/cn/zh-cn/',
  test: 'https://test-toc-v5.aqumon.com/cn/zh-cn/',
}[process.env.GATSBY_ENV];
export const AppContext = React.createContext(null);
export const useAppContext = () => useContext(AppContext);
export const AppProvider = ({ children }) => {
  const queryObj = queryString.parse(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  if (queryObj['qd']) sessionStorage.qd = queryObj['qd'];
  const footerFormRef = useRef(null);
  const institutionsFormRef = useRef(null);
  const professionalInvestorsFormRef = useRef(null);
  const [errorCode, setErrorCode] = useState(null);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [blogTopic, setBlogTopic] = useState('');
  const intl = useIntl();
  return (
    <AppContext.Provider
      value={{
        apiUrl,
        mainLandUrl,
        footerFormRef,
        institutionsFormRef,
        professionalInvestorsFormRef,
        errorCode,
        setErrorCode,
        signUpSuccess,
        setSignUpSuccess,
        blogTopic,
        setBlogTopic,
        appStoreUrl: {
          en: 'https://itunes.apple.com/us/app/aqumon/id1313188337',
          'zh-cn': 'https://itunes.apple.com/cn/app/aqumon/id1313188337',
          'zh-hk': 'https://itunes.apple.com/hk/app/aqumon/id1313188337',
        }[intl.locale],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
