import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { css } from '@emotion/core';
import { useLocation } from '@reach/router';
import { mainColor, bespokeMainColor } from '../../constants/colors';
import { useIntl } from 'gatsby-plugin-intl';
import { Tablet } from '../../constants/responsive';

const LanguageOption = ({ code, text }) => {
  const intl = useIntl();
  const location = useLocation();
  const active = intl.locale === code;
  const currentPathArr = location.pathname.split('/');
  const langIndex = currentPathArr.findIndex((e) => e === intl.locale);
  currentPathArr[langIndex] = code;
  const newPath = currentPathArr.slice(langIndex).join('/');

  const isInstitutions = location.pathname.split('/')[3] === 'institutions';
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';
  const isBespoke = ['bespoke', 'appointment'].includes(
    location.pathname.split('/')[4]
  );
  const isWhite = isInstitutions || isProfessional;

  const textColor = 'black';
  return (
    <GatsbyLink to={`/${newPath}`}>
      <div
        css={css`
          font-weight: ${active ? '700' : '500'};
          color: ${active ? mainColor : textColor};
          cursor: pointer;
        `}
      >
        {text}
      </div>
    </GatsbyLink>
  );
};

const Language = () => {
  const location = useLocation();

  const isInstitutions = location.pathname.split('/')[3] === 'institutions';
  const isBespoke = ['bespoke', 'appointment'].includes(
    location.pathname.split('/')[4]
  );
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';

  const isWhite = isInstitutions || isProfessional;

  const textColor = 'black';

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 110px;
        color: ${textColor};
        margin-bottom: 32px;
        ${Tablet} {
          margin-bottom: 0;
        }
      `}
    >
      <LanguageOption code='zh-cn' text='简' />
      <div>|</div>
      <LanguageOption code='zh-hk' text='繁' />
      <div>|</div>
      <LanguageOption code='en' text='EN' />
    </div>
  );
};

export default Language;
