import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Tablet } from '../../constants/responsive';
import env from '../../constants/environment';
import '../../styles/main.scss';
import { useMount } from 'react-use';

const Main = ({ children }) => {
  const [isAQMAPP, setIsAQMAPP] = useState(false);
  useMount(() => {
    setIsAQMAPP(env.isAQMAPP);
  });
  return (
    <main
      className={`${isAQMAPP ? '' : 'non-bespoke'}`}
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        min-width: 320px;
        overflow: hidden;
        ${Tablet} {
        }
      `}
    >
      {children}
    </main>
  );
};

export default Main;
