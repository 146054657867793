import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby-plugin-intl';
import Container from '../Container';
import Logo from '../Logo';
import DesktopMenu from './DesktopMenu';
import HamburgerMenu from './HamburgerMenu';
import { Desktop, Tablet } from '../../constants/responsive';
import { useLocation } from '@reach/router';

const Header = () => {
  return (
    <header
      id='header'
      css={css`
        position: fixed;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 70px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        user-select: none;
        background: white;
        z-index: 10;
        ${Tablet} {
          height: 100px;
        }
      `}
    >
      <Container
        css={css`
          padding: 0px 20px;
          min-width: 320px;
          width: 100%;
          ${Tablet} {
            width: 768px;
          }
          ${Desktop} {
            padding: 0;
            width: 1200px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
          `}
        >
          <Link
            to='/'
            css={css`
              width: 116px;
              margin-top: 5px;
              cursor: pointer;
              ${Tablet} {
                width: 180px;
              }
              ${Tablet} {
                width: 180px;
              }
              ${Desktop} {
                width: 180px;
              }
            `}
          >
            <Logo />
          </Link>
          <DesktopMenu />
          <HamburgerMenu />
        </div>
      </Container>
    </header>
  );
};

export default Header;
