import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';

// 【空白高度】
const Blank = ({ height = [0, 0, 0], width = [0, 0, 0], ...rest }) => {
  return (
    <div
      css={css`
        height: ${height[0]}px;
        width: ${width[0]}px;
        ${Tablet} {
          height: ${height[1]}px;
          width: ${width[1]}px;
        }
        ${Desktop} {
          height: ${height[2]}px;
          width: ${width[2]}px;
        }
      `}
      {...rest}
    ></div>
  );
};

export default Blank;
