import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useLocation } from '@reach/router';

const Logo = ({ type = 'header' }) => {
  const location = useLocation();
  const isInstitutions = location.pathname.split('/')[3] === 'institutions';
  const isBespoke = ['bespoke', 'appointment'].includes(
    location.pathname.split('/')[4]
  );
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';
  const isWhite = isInstitutions || isProfessional;

  const data = useStaticQuery(graphql`
    query {
      pinkHeaderTablet: file(relativePath: { eq: "logo/pink.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      pinkHeaderMobile: file(relativePath: { eq: "logo/pink.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      pinkFooter: file(relativePath: { eq: "logo/pink.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      whiteHeaderTablet: file(relativePath: { eq: "logo/white.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      whiteHeaderMobile: file(relativePath: { eq: "logo/white.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      whiteFooter: file(relativePath: { eq: "logo/white.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      purpleHeaderTablet: file(relativePath: { eq: "logo/bespoke.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      purpleHeaderMobile: file(relativePath: { eq: "logo/bespoke.png" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 99) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const sources = {
    pink: {
      header: [
        data.pinkHeaderMobile.childImageSharp.fluid,
        {
          ...data.pinkHeaderTablet.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
      footer: data.pinkFooter.childImageSharp.fluid,
    },
    white: {
      header: [
        data.whiteHeaderMobile.childImageSharp.fluid,
        {
          ...data.whiteHeaderTablet.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
      footer: data.whiteFooter.childImageSharp.fluid,
    },
    purple: {
      header: [
        data.purpleHeaderMobile.childImageSharp.fluid,
        {
          ...data.purpleHeaderTablet.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
      footer: data.pinkFooter.childImageSharp.fluid,
    },
  };

  return <Img fluid={sources['pink'][type]} imgStyle={{ objectFit: 'fill' }} />;
};

export default Logo;
