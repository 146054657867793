import React from 'react';
import { mainColor } from '../../constants/colors';
import { Global, css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { useIntl } from 'gatsby-plugin-intl';

const Styles = () => {
  const intl = useIntl();
  return (
    <Global
      styles={css`
        // 英文字体使用Unica77
        @font-face {
          font-family: 'Unica77';
          src: url('https://oss-static.aqumon.com/assets/fonts/Unica77/Unica77LLTT-Regular.ttf')
            format('truetype');
          font-weight: normal;
        }
        @font-face {
          font-family: 'Unica77';
          src: url('https://oss-static.aqumon.com/assets/fonts/Unica77/Unica77LLTT-Medium.ttf')
            format('truetype');
          font-weight: 500;
        }
        @font-face {
          font-family: 'Unica77';
          src: url('https://oss-static.aqumon.com/assets/fonts/Unica77/Unica77LLTT-Bold.ttf')
            format('truetype');
          font-weight: bold;
        }
        body {
          font-family: ${intl.locale === 'en' ? `"Unica77",` : ``} 'PingFang SC',
            'PingFangSC', 'PingFang TC', 'PingFangTC', 'PingFang HK',
            'PingFangHK', 'Hiragino Sans GB', 'STHeiti', 'Heiti SC', 'Heiti TC',
            'Microsoft YaHei', '微软雅黑', '黑体', 'Microsoft Jhenghei',
            '微軟正黑體', 'SimSun', 'Droid Sans Fallback', 'Noto Sans SC',
            'Noto Sans TC', 'Noto Sans HK', 'Noto Sans CJK',
            'WenQuanYi Micro Hei', sans-serif;
        }
        * {
          vertical-align: baseline;
          box-sizing: border-box;
          font-weight: inherit;
          font-family: inherit;
          // font-style: inherit;
          font-size: 100%;
          border: 0 none;
          outline: 0;
          padding: 0;
          margin: 0;
        }
        sup {
          vertical-align: super;
          font-size: smaller;
        }
        ul {
          padding-left: 20px;
        }
        li {
          margin-bottom: 30px;
        }
        a,
        a:active,
        a:visited {
          color: black;
          transition: all 0.2s ease;
          text-decoration: none;
        }
        a:hover {
          color: ${mainColor};
        }
        b {
          font-weight: bold;
        }
        strong {
          color: ${mainColor};
        }
        em {
          display: inline;
          font-weight: 300;
        }
        code {
          width: 100%;
          text-align: center;
          color: red;
          display: block;
          font-weight: bold;
        }
        h1 {
          display: inline-block;
          font-size: 25px;
          margin-bottom: 24px;
          font-weight: bold;
          line-height: 30px;
          ${Tablet} {
            font-size: 35px;
            line-height: 40px;
          }
          ${Desktop} {
            line-height: 60px;
            font-size: 50px;
          }
        }
        h2 {
          display: inline-block;
          font-size: 20px;
          margin-bottom: 24px;
          font-weight: bold;
          line-height: 1.5;
          ${Tablet} {
            font-size: 20px;
          }
          ${Desktop} {
            line-height: 35px;
            font-size: 30px;
          }
        }
        h3 {
          display: inline-block;
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 24px;
          ${Tablet} {
            font-size: 20px;
          }
          ${Desktop} {
            font-size: 25px;
          }
        }
        h4 {
          display: inline-block;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 24px;
        }
        h5 {
          display: inline-block;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          ${Desktop} {
            font-size: 20px;
            line-height: 30px;
          }
        }
        h6 {
          display: inline-block;
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 24px;
          ${Desktop} {
            font-size: 16px;
          }
        }
        hr {
          flex-basis: 100%;
          height: 0;
          margin: 0;
          border: 0;
        }
      `}
    />
  );
};

export default Styles;
