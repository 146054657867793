import React from 'react';
import { mainColor } from '../../constants/colors';
import { css } from '@emotion/core';
import { Tablet, Desktop } from '../../constants/responsive';

const Button = ({
  children,
  text = '',
  outline = false,
  disabled = false,
  width = 0,
  height = 0,
  onClick = () => {},
  scrollToRef = null,
  fontSize,
  ...rest
}) => {
  return (
    <div
      css={css`
        width: ${width ? `${width}px` : 'auto'};
        height: ${height ? `${height}px` : '27px'};
        border-radius: 41px;
        border: 1px solid ${mainColor};
        box-shadow: ${outline ? 'none' : '0 3px 6px 0 rgba(238, 47, 121, 0.5)'};
        background-color: ${outline ? 'transparent' : mainColor};
        color: ${outline ? mainColor : 'white'};
        font-size: ${fontSize || '12px'};
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        cursor: pointer;
        transition: all 0.2s ease;
        opacity: ${disabled ? 0.4 : 1};
        pointer-events: ${disabled ? 'none' : 'auto'};
        ${Tablet} {
          height: ${height ? `${height}px` : '36px'};
          font-size: ${fontSize || '14px'};
        }
        ${Desktop} {
          height: 36px;
          font-size: ${fontSize || '14px'};
        }
      `}
      {...rest}
      onClick={() => {
        onClick();
        if (scrollToRef) {
          window.scrollTo({
            top: scrollToRef.current.offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        }
      }}
    >
      {text}
      {children}
    </div>
  );
};

export default Button;
