import React from "react";
import { css } from "@emotion/core";
import { Tablet, Desktop } from "../../constants/responsive";

const Container = ({ children, ...rest }) => {
  return (
    <div
      css={css`
        padding: 0px 20px;
        min-width: 320px;
        width: 100%;
        ${Tablet} {
          width: 768px;
        }
        ${Desktop} {
          width: 1200px;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Container;
