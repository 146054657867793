import React from 'react';
import PropTypes from 'prop-types';
import Styles from '../Styles';
import Header from '../Header';
import Main from '../Main';
import Footer from '../Footer';
import { AppProvider } from '../../context/AppContext';
import ErrorPopup from '../Footer/ErrorPopup';
import { useLifecycles } from 'react-use';
import { Helmet } from 'react-helmet';

const Layout = ({
  children,
  footerForm = false,
  footerBand = true,
  footerBlank = false,
  showHeader = true,
  showFooter = true,
}) => {
  useLifecycles(
    () => {
      console.log('MOUNTED');

      if (document.domain.includes('aqumon')) {
        document.domain = 'aqumon.com';
      }
    },
    () => console.log('UNMOUNTED')
  );

  return (
    <AppProvider>
      {/* linked埋点 - linkedIn insight tag */}

      <Helmet>
        <script type='text/javascript' src='/js/mpaas.js'></script>
        <script src='https://gw.alipayobjects.com/os/lib/alipay/mpaas-mtracker/1.1.3/dist/index.min.js'></script>
        <script
          type='text/javascript'
          src='https://oss-static.aqumon.com/download/aqumon/version_info.js'
        ></script>
        <script
          type='text/javascript'
          src='/js/linkedIn-insight-tag.js'
        ></script>
        <noscript>
          {`<img
              height='1'
              width='1'
              style='display:none;'
              alt=''
              src='https://px.ads.linkedin.com/collect/?pid=873099&fmt=gif'
            />`}
        </noscript>
      </Helmet>
      <ErrorPopup />
      <Styles />
      {showHeader ? <Header /> : ''}
      <Main>{children}</Main>
      {showFooter ? (
        <Footer
          footerForm={footerForm}
          footerBand={footerBand}
          footerBlank={footerBlank}
        />
      ) : (
        ''
      )}
    </AppProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
