/*
 * @Author: yanbao dong
 * @Date: 2022-08-26 17:10:37
 * @LastEditors: yanbao dong
 * @LastEditTime: 2022-09-06 09:42:55
 * @Description: file content
 */
export const menu = {
  // product: ["individuals", "institutions", "professional-investors"],
  /*   product: [
    {
      individuals: [
        'long-term',
        // 'smart-stock',
        'cash-management',
        // 'stock-trading',
      ],
      institutions: ['advisory', 'selection', 'smart-fund'],
      'bespoke-investors': ['mainland-china', 'hk-overseas'],
    },
  ], */
  wealthTech: [
    {
      product: [
        'raas',
        // 'smart-stock',
        'Fund-Supermarket',
        'smart-fund',
        // 'stock-trading',
      ],
      Solutions: ['Brokers', 'Manager', 'Banks', 'Company'],
    },
  ],
  'S-T-S': ['Digital', 'Mainland', 'App', 'fee'],
  /* blog: [
    "market-insights",
    "investing-101",
    "company-updates",
    "research-analytics",
  ], */
  about: ['who-we-are', 'career', 'press', 'contact-us'],
  learn: ['blog', 'stories', 'how-we-invest'],
  // resources: ['faq', 'fee', 'security', 'legal'],
  // promotions: [],
};
