import React from 'react';
import { css } from '@emotion/core';
import { useLocation } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import SFC from '../../svgs/sfc.svg';
import BOC from '../../svgs/boc.svg';
import ICC from '../../svgs/icc.svg';
import { Desktop, Tablet } from '../../constants/responsive';

const Group = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        margin-bottom: 40px;
        margin-right: 40px;
        ${Tablet} {
          margin-right: 0px;
        }
      `}
    >
      {children}
    </div>
  );
};

const Icon = ({ children }) => {
  const location = useLocation();
  // const isWhite = ["institutions", "professional-investors"].includes(
  //   location.pathname.split("/")[3]
  // );
  const isProfessional = location.pathname.split('/')[4] === 'hk-overseas';
  const isWhite = isProfessional;
  const fill = isWhite ? 'white' : 'black';
  return (
    <div
      css={css`
        width: 50px;
        ${Desktop} {
          margin-left: 20px;
        }
        * {
          fill: ${fill};
        }
      `}
    >
      {children}
    </div>
  );
};

const Description = ({ title, text, width = 160 }) => {
  return (
    <div
      css={css`
        font-size: 10px;
        width: ${width}px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
      `}
    >
      <b
        css={css`
          padding-bottom: 5px;
        `}
      >
        {title}
      </b>
      <p>{text}</p>
    </div>
  );
};

const Shield = () => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        ${Tablet} {
          justify-content: space-between;
        }
        ${Desktop} {
          margin-left: -20px;
          width: auto;
          justify-content: flex-start;
        }
      `}
    >
      <Group>
        <Icon>
          <SFC />
        </Icon>
        <Description
          title={intl.formatMessage({ id: 'footer.sfc.title' })}
          text={intl.formatMessage({ id: 'footer.sfc.text' })}
          width={160}
        />
      </Group>
      <Group>
        <Icon>
          <BOC />
        </Icon>
        <Description
          title={intl.formatMessage({ id: 'footer.boc.title' })}
          text={intl.formatMessage({ id: 'footer.boc.text' })}
          width={148}
        />
      </Group>
      <Group>
        <Icon>
          <ICC />
        </Icon>
        <Description
          title={intl.formatMessage({ id: 'footer.icc.title' })}
          text={intl.formatMessage({ id: 'footer.icc.text' })}
          width={149}
        />
      </Group>
    </div>
  );
};

export default Shield;
