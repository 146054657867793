/*
* Author: LJH
* Date: 2022/1/7
* Description:
*/
import { useState, useEffect } from "react";
import Transition from '../Transition'
import './Toast.scss';

function Toast(props) {
  const { text, children } = props;
  const [show, setShow] = useState(true);
  /*useEffect(() => {
    return () => setShow(false);
  }, []);*/
  return (
    <Transition name="opacity" show={show} duration="300">
      <div className="_toast">
        {children || text}
      </div>
    </Transition>
  );
}
export default Toast;
