/*
* Author: LJH
* Date: 2020/10/9
* Description: 过度组件
* props:
*   delayMount: 延迟渲染
*/
import React, {useEffect, useState, useRef} from 'react';

function whichTransitionEvent(){
  if(!window.whichTransitionEvent) {
    const el = document.createElement('surface'),
      transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
      }

    for(let t in transitions){
      if( el.style[t] !== undefined ){
        window.whichTransitionEvent = transitions[t];
        break;
      }
    }
  }
  return window.whichTransitionEvent;
}

function Transition(props) {
  const el = useRef(null);
  const [firstRender, setFirstRender] = useState(false);
  const [cn, setCn] = useState('');
  const [havedom, setHavedom] = useState(false);
  useEffect(() => {
    // console.log('Transition useEffect:', props.name, props.show, props.duration)
    let duration = props.duration;
    if(duration && typeof duration == 'object') {
      duration = props.show ? duration.enter||0 : duration.leave||0;
    }
    if(props.show) {
      setFirstRender(true);
      setHavedom(true);
      setCn(`${props.name}-enter`);
      setTimeout(()=>{
        setCn(`${props.name}-enter-active `);
      }, 10);
    }
    if(!props.show) {
      setCn(`${props.name}-leave-active ${props.name}-leave-to`);
    }
    if(![undefined, null].includes(duration) && !props.show) {
      setTimeout(() => {
        setHavedom(false);
        if(!props.show) {
          setCn(`${props.name}-leave-active `);
        }
      }, duration)
    }
    if([undefined, null].includes(duration) && el.current) {
      el.current.addEventListener(whichTransitionEvent(), () => {
        console.log(props.name,props.show)
        if(props.show) {
          setCn('');
        }
        if(!props.show) {
          setHavedom(false)
        }
      }, {once: true});
    }
  }, [props.show]);
  const style = { display: havedom ? '' : 'none' };
  // 替换第一层div
  /*const {className, children, ...attr} = {...props.children.props};
  if(props.delayMount || !firstRender)
    return havedom ?
      <div {...attr} ref={el} className={`${cn} ${className}`}>{children}</div> : '';
  else
    return  <div {...attr} ref={el} style={style} className={`${cn} ${className}`}>{children}</div>;*/

  // 多生成一个包裹div
  const attrs = {};
  if(props.children[0]) {
    const scoped_name = Object.keys(props.children[0].props).find(it => it.indexOf('data-v-')==0);
    attrs[scoped_name] = '';
  }
  if(props.delayMount || !firstRender)
    return havedom ? <div {...attrs} ref={el} className={`${cn} ${props.className}`}>{props.children}</div> : '';
  else
    return <div {...attrs} ref={el} style={style} className={`${cn} ${props.className}`}>{props.children}</div>;
}

export default Transition

