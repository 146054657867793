import { useIntl } from 'gatsby-plugin-intl';
import React, { useRef, useState } from 'react';
import Button from '../Button';
import { langMap } from '../../constants/dataMap';

const ReqDemoBtn = ({ height }) => {
  const intl = useIntl();
  const lang = langMap[intl.locale];
  return (
    <a
      onClick={() => {
        const devOrTestEvnReg = /(^test-)|(^localhost)|(^127)|(^0)/;
        let domain = `https://portal.aqumon.com/#/question?lang=${lang}`;
        if (devOrTestEvnReg.test(window.location.hostname)) {
          // 测试环境
          domain = `https://test-portal.aqumon.com/#/question?lang=${lang}`;
        }
        window.location.href = domain;
      }}
    >
      <Button
        height={height}
        text={intl.formatMessage({ id: 'menu.Req-Demo' })}
      />
    </a>
  );
};
export default ReqDemoBtn;
